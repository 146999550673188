<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Ubah <b>Produk Layanan</b>
      </div>
    </b-alert>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <!-- <Card :title="'Edit Biaya Layanan'"> -->
          <!-- <template v-slot:body> -->
          <template>
            <Form
              :form="form"
              purpose="edit"
              :route="'payment-categories/' + form.id"
            />
          </template>
        <!-- </Card> -->
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/masters/payment-categories/FormEdit.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: '',
        price: '',
        description: '',
        payment_category_type_id: '',
        doctor_commission: '',
        hospital_income: '',
        isBundle : false,
      },
    }
  },

  methods: {

    async get() {
      this.form = await module.get('payment-categories/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/payment-category/list')
      } else {
        this.form['_method'] = 'put'
        
        if(this.form.isBundle == 1){
          this.form.isBundle = true
        }else{
          this.form.isBundle = false
        }

        this.form.price = parseInt(this.form.price).toLocaleString('id-ID')
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Layanan", route: "" },
      { title: "Daftar Produk Layanan", route: "/payment-category/list" },
      { title: "Ubah" },
    ])
    // Get Data
    this.get()
  },

}

</script>